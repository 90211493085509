import styled from 'styled-components'
import media from '../../utils/breakpoints'

export const SecondaryHeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  img {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
  }
  img:last-of-type {
    right: 0;
    left: auto;
  }

  ${media.lg} {
    img {
      display: block;
    }
  }
`
export const SecondaryHeaderInner = styled.header`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 1.5rem 1rem;
  h1 {
    padding: 1rem 0;
  }
  p {
    font-size: 1.1rem;
  }

  ${media.md} {
    padding: 3rem 1rem;
  }
`
