import React from 'react'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import AffiliatePage from '../components/affiliate/affiliate-page'
import SEO from '../components/seo/seo'
function Affiliate() {
  const title = 'Blue Geek Hosting Affiliate'
  const subtitle =
    "It is easy, quick and FREE! We'll provide you with everything - your unique affiliate link."

  return (
    <>
      <SEO title="Affiliate" />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <AffiliatePage />
    </>
  )
}

export default Affiliate
