import styled from 'styled-components'
import media from '../../utils/breakpoints'
import { HeadingWrapper } from '../../heading/heading-styles'
export const TwoBlockFeaturesWrapper = styled.section`
  padding: 1rem 0;
  margin: 1rem 0;
  background: ${({ background }) => background && 'var(--cool-white)'};
  ${HeadingWrapper} {
    padding-top: 0%;
  }
  ${media.md} {
    padding: 1.5rem 0;
    margin: 1.5rem 0;
  }
`
export const TwoBlockFeaturesInner = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  div.features {
    display: grid;
    place-content: center;
    div {
      padding: 1rem 0;
      h3 {
        padding-bottom: 0.5rem;
      }
    }
  }

  ${media.md} {
    grid-template-columns: 1fr 1fr;
  }
`
