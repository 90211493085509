import styled from 'styled-components'
import media from '../utils/breakpoints'
export const HeadingWrapper = styled.div`
  padding: 1.5rem 1rem;
  max-width: 800px;
  margin: 0 auto;
  p,
  h1 {
    text-align: center;
  }
  h1 {
    padding-bottom: 1rem;
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }

  ${media.md} {
    padding: 3rem 0;
    h1 {
      font-size: 2.5rem;
    }
  }
`
