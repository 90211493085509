import affiliateImage from '../../images/features/affiliate2.svg'

const affiliateSteps = {
  title: 'Joining the Program',
  subtitle:
    'Earn at least 60% per eligible sale. Get help at every step. Grow performance – increase commission size. We will help you along the way.',
  featureImage: affiliateImage,
  features: [
    {
      name: 'Instant sign up',
      desc:
        'Sign up in less than a minute. Completely free. Get verified and gain access to your affiliate dashboard.'
    },
    {
      name: 'Track your performance',
      desc:
        'Access one of the best affiliate control panels in the industry. Easily track and optimize your campaigns.'
    },
    {
      name: 'Get your payout',
      desc:
        "We share revenue fairly. Every eligible sale made earns you at least 60% commissions. And that's just the start."
    }
  ]
}

export default affiliateSteps
