import React from 'react'
import {
  SecondaryHeaderWrapper,
  SecondaryHeaderInner
} from './secondary-header-styles'
import wireSVG from '../../../images/illustrations/wire-server.svg'
import upDown from '../../../images/illustrations/up-download.svg'
import securityCheck from '../../../images/illustrations/security-check.svg'
import heroLeft from '../../../images/illustrations/hero-left.svg'
import heroRight from '../../../images/illustrations/hero-right.svg'
// import sheader1 from '../../../images/illustrations/s-header1.svg'
// import sheader2 from '../../../images/illustrations/s-header2.svg'
// import sheader3 from '../../../images/illustrations/s-header3.svg'

function SecondaryHeader({ title, subtitle }) {
  const spaceIndex = title.indexOf(' ')
  const highlighHeading = title.slice(0, spaceIndex)
  const restHeading = title.slice(spaceIndex)

  const images = [wireSVG, upDown, securityCheck, heroLeft, heroRight]
  const random = Math.floor(Math.random() * 5)

  return (
    <SecondaryHeaderWrapper>
      <img src={images[random]} alt="man illustration" />
      <img
        src={images[random + 1] || images[random - 1]}
        alt="man illustration"
      />
      <SecondaryHeaderInner>
        <h1>
          <span className="paint-bg">{highlighHeading}</span> {restHeading}
        </h1>
        <p>{subtitle}</p>
      </SecondaryHeaderInner>
    </SecondaryHeaderWrapper>
  )
}

export default SecondaryHeader
