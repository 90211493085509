import React from 'react'
import { AffiliateWrapper, AffiliateInner } from './affiliate-page-styles'
import { Container, TwoColumnWrapper } from '../common/common-styles'
import affiliateSVG from '../../images/features/affiliate.svg'
import AffiliateSteps from '../features/two-block-feature/affiliate-steps/affiliate-steps'
import Button from '../common/cta-button/button'

function AffiliatePage() {
  return (
    <AffiliateWrapper>
      <Container>
        <AffiliateInner>
          <TwoColumnWrapper reverse>
            <div className="content">
              <h3>High Conversion Rate</h3>
              <p>
                Our brand & effective promo materials mean the traffic you send
                to Hostinger will convert.
              </p>
              <h3>Easy to Start and Maintain</h3>
              <p>
                Professionally designed banner packages, seasonal e-books, and
                more assets to increase conversions.
              </p>
              <h3>A Special Affiliate Program</h3>
              <p>
                Earn, grow, and expand with Hostinger's Affiliate Program.
                Everything you need to start promoting is a click away.
              </p>
              <p>
                <Button
                  href="https://my.bluegeekhosting.com/affiliates.php"
                  size="big"
                >
                  Sign Up
                </Button>
              </p>
            </div>
            <div className="image">
              <img src={affiliateSVG} alt="Affiliate Marketing Illustration" />
            </div>
          </TwoColumnWrapper>

          <AffiliateSteps />
        </AffiliateInner>
      </Container>
    </AffiliateWrapper>
  )
}

export default AffiliatePage
