import React from 'react'
import { Container } from '../../common/common-styles'
import {
  TwoBlockFeaturesWrapper,
  TwoBlockFeaturesInner
} from './two-block-feature-styles'
import PrimaryHeading from '../../heading/primary-heading'

function TwoBlockFeatures({
  title,
  subtitle,
  features,
  featureImage,
  background
}) {
  return (
    <TwoBlockFeaturesWrapper background={background}>
      <Container>
        <PrimaryHeading title={title} subtitle={subtitle} />
        <TwoBlockFeaturesInner>
          <div className="image">
            <img src={featureImage} alt="Features" />
          </div>
          <div className="features">
            {features.map(feature => (
              <div>
                <h3>{feature.name}</h3>
                <p>{feature.desc}</p>
              </div>
            ))}
          </div>
        </TwoBlockFeaturesInner>
      </Container>
    </TwoBlockFeaturesWrapper>
  )
}

export default TwoBlockFeatures
