import React from 'react'
import { HeadingWrapper } from './heading-styles'

function PrimaryHeading({ title, subtitle }) {
  return (
    <HeadingWrapper>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </HeadingWrapper>
  )
}

export default PrimaryHeading
